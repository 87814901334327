/* eslint-disable no-undef */
/**
 *  When considering persistence for storing values in Office documents using Office JavaScript API (Office.js), there are two main approaches:
 *  Settings and Custom Properties.
 *
 *  If you need to store metadata that should persist within the document regardless of the device or user, Custom Properties are more appropriate because they travel with the document.
 *
 *  If you need to store add-in-specific configurations that are only relevant to a particular user or session on a specific device, then Settings are suitable.
 *
 * Reference: https://stackoverflow.com/questions/48194643/officejs-retrieving-document-id
 * */
export const useOfficeProperties = () => {
  const hostApplication: string = Office.context.host.toString();

  const setCustomProperties = async (key: string, value: string) => {
    if (hostApplication === "Word") {
      await Word.run(async (context) => {
        context.document.properties.customProperties.add(key, value);
        await context.sync();
      });
    }
    if (hostApplication === "Excel") {
      await Excel.run(async (context) => {
        context.workbook.properties.custom.add(key, value);
        await context.sync();
      });
    }
    if (hostApplication === "PowerPoint") {
      // TODO: Yet to figure out how to setup custom properties in PowerPoint.
      const settings = Office.context.document.settings;
      settings.set(key, value);
    }
  };

  const getCustomProperties = async (key: string) => {
    if (hostApplication === "Word") {
      return await Word.run(async (context) => {
        const properties = context.document.properties.customProperties;
        properties.load("key,type,value");
        await context.sync();
        return properties.items.find((item) => item.key === key)?.value;
      });
    }
    if (hostApplication === "Excel") {
      return await Excel.run(async (context) => {
        const properties = context.workbook.properties.custom;
        properties.load("key,type,value");
        await context.sync();
        return properties.items.find((item) => item.key === key)?.value;
      });
    }
    if (hostApplication === "PowerPoint") {
      // TODO: Yet to figure out how to setup custom properties in PowerPoint.
      const settings = Office.context.document.settings;
      return settings.get(key);
    }
  };
  return { setCustomProperties, getCustomProperties };
};
