import { Card, makeStyles, tokens } from "@fluentui/react-components";
import React from "react";

const useStyle = makeStyles({
  brandTitle: {
    color: "#858585",
    fontSize: "1.2rem",
    fontWeight: tokens.fontWeightSemibold,
  },
  brandContent: {
    color: "#858585",
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase400,
  },
  copyright: {
    color: "#858585",
    fontSize: tokens.fontSizeBase200,
  },
});

/**
 * The `FooterCard` component renders a card with branding information for a company called QR Mark.
 * @returns A FooterCard component is being returned, which renders a Card component with three spans
 * inside it. The spans have classNames assigned using the style object returned from the useStyle
 * hook.
 */
const FooterCard = () => {
  const style = useStyle();
  return (
    <Card>
      <span className={style.brandTitle}>QR Mark</span>
      <span className={style.brandContent}>Securing document made easy</span>
      <span className={style.copyright}> &copy; 2024 QR Mark. All right reserved.</span>
    </Card>
  );
};

export default FooterCard;
