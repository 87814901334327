import { HashRouter, Route, Routes } from "react-router-dom";
import SignIn from "../pages/SignIn";
import ProtectedRoutes from "./ProtectedRoutes";
import * as React from "react";

const GenerateVerificationImage = React.lazy(() => import("../pages/GenerateVerificationImage"));

interface RouterProps {
  title: string;
}

/* This code snippet defines a functional component named `AppRouter` that takes in a prop `title` of
type string.
Inside the component, it sets up routing using `HashRouter` from `react-router-dom`. */
export const AppRouter = (props: RouterProps) => {
  return (
    <>
      <HashRouter future={{ v7_startTransition: true }}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <SignIn
                logo="assets/logo-filled.svg"
                title={props.title}
                message="Secure Original Documents With QR Mark"
              />
            }
          />

          <Route element={<ProtectedRoutes />}>
            <Route path="dashboard" element={<GenerateVerificationImage />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
};
