/* eslint-disable no-undef */

export const useInsertImage = () => {
  const hostApplication: string = Office.context.host.toString();

  const insertInOfficeApp = (verificationImage: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(verificationImage);

    if (hostApplication === "Word") {
      reader.onloadend = async () => {
        // eslint-disable-next-line no-undef
        await Word.run(async (context) => {
          const dataUrl: string = reader.result as string;
          const base64EncodedImage: string = dataUrl.split(",")[1];
          context.document.body.paragraphs.getFirst().insertInlinePictureFromBase64(base64EncodedImage, "Start");

          await context.sync();
        });
      };
    }
    if (hostApplication === "Excel") {
      reader.onloadend = async () => {
        await Excel.run(async () => {
          const dataUrl: string = reader.result as string;
          const base64EncodedImage: string = dataUrl.split(",")[1];
          Excel.run(function (context) {
            var sheet = context.workbook.worksheets.getActiveWorksheet();
            var image = sheet.shapes.addImage(base64EncodedImage);
            image.altTextDescription = "QR Mark Verification Image";
            return context.sync();
          }).catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
              console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
          });
        });
      };
    }
    if (hostApplication === "PowerPoint") {
      reader.onloadend = async () => {
        await PowerPoint.run((context) => {
          const dataUrl: string = reader.result as string;
          const base64EncodedImage: string = dataUrl.split(",")[1];
          Office.context.document.setSelectedDataAsync(base64EncodedImage, {
            coercionType: Office.CoercionType.Image,
            imageLeft: 50,
            imageTop: 50,
          });
          return context.sync();
        });
      };
    }
  };
  return { insertInOfficeApp };
};
