import { useAxios } from "../../utils/hooks";

/**
 * The function `useVerifiedCDList` makes an asynchronous request to retrieve a list of custom domains.
 * @returns The `useVerifiedCDList` function is returning an object with a property `response` that
 * contains the result of the Axios request made to the "/custom-domain/list/" endpoint using the GET
 * method.
 */
const useVerifiedCDList = async () => {
  const { response } = await useAxios({
    url: "/custom-domain/list/",
    method: "GET",
  });
  return { response };
};

export default useVerifiedCDList;
