import { Card, CardHeader, CardPreview, makeStyles, tokens } from "@fluentui/react-components";
import React from "react";
import NavMenu from "../menu/NavMenu";

interface BrandHeaderProps {}

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
  },
  brandTitle: {
    color: tokens.colorStatusSuccessForeground1,
    fontSize: "2rem",
    fontWeight: tokens.fontWeightSemibold,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  caption: {
    marginTop: "5px",
    color: tokens.colorNeutralForeground3,
    fontSize: tokens.fontSizeBase200,
  },
});

/**
 * The `BrandHeader` function in TypeScript React renders a card component with a logo icon, brand
 * title, and navigation menu.
 * @returns The `BrandHeader` component is being returned, which consists of a `Card` component with a
 * logo icon, a brand title, and a navigation menu.
 */
const BrandHeader: React.FC<BrandHeaderProps> = () => {
  const styles = useStyles();
  return (
    <>
      <Card orientation="horizontal" className={styles.card}>
        <CardPreview>
          <img src="assets/logo-icon.svg" alt="QR Mark Icon" style={{ width: "64px", height: "64px" }} />
        </CardPreview>

        <CardHeader
          className={styles.cardHeader}
          header={<span className={styles.brandTitle}>QR Mark</span>}
          action={<NavMenu />}
        />
      </Card>
    </>
  );
};

export default BrandHeader;
