import React from "react";
import axios from "axios";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useIsAuthenticated from "../services/authentication/useIsAuthenticated";
import { useLocalStorage } from "../utils/hooks";

/**
 * If the token is expired, render the Outlet component, otherwise redirect to the SignIn page
 */
/**
 * The `ProtectedRoutes` component checks if the user is authenticated and sets the Authorization
 * header before rendering the child components or redirects to the home page if not authenticated.
 * @returns The `ProtectedRoutes` component is returning either the `Outlet` component if the user is
 * authenticated, or a `Navigate` component redirecting to the home page with the current location
 * state if the user is not authenticated.
 */
const ProtectedRoutes = () => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { getItem } = useLocalStorage();
  if (isAuthenticated) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${getItem("access_token")}`;
    return <Outlet />;
  }
  return <Navigate to="/" state={{ from: location }} replace />;
};

export default ProtectedRoutes;
