/* eslint-disable */

export const useLocalStorage = () => {

  const setItem = (key: string, value: string) => {
    const partitionKey = Office.context.partitionKey;

    if (partitionKey) localStorage.setItem(partitionKey + key, value);
    else localStorage.setItem(key, value);
  };

  const getItem = (key: string): string => {
    const partitionKey:string  = Office.context.partitionKey;
    if (partitionKey) return localStorage.getItem(partitionKey + key)
    else return localStorage.getItem(key)
  };

  const removeItem = (key: string) => {
    const partitionKey:string  = Office.context.partitionKey;
    if (partitionKey) localStorage.removeItem(partitionKey + key);
    else localStorage.removeItem(key)
  }
  return { setItem, getItem, removeItem };
};
