/* eslint-disable no-undef */
/**
 * The `insertImage` function in TypeScript inserts an image anchored to the start of the last
 * paragraph in a Word document using a base64 encoded image.
 * @param {string} base64Image - Base64 encoded string representing the image data.
 */
export async function insertImage(base64Image: string) {
  // Inserts an image anchored to the last paragraph.
  await Word.run(async (context) => {
    context.document.body.paragraphs.getFirst().insertInlinePictureFromBase64(base64Image, "Start");

    await context.sync();
  });
}
