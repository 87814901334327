import React, { FC } from "react";
import { Button, Image, Toast, ToastTitle, useToastController } from "@fluentui/react-components";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import useConvertToken from "../../services/authentication/useConvertToken";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../utils/hooks";
import { useErrorBoundary } from "react-error-boundary";
import axios from "axios";

/**
 * The `ContinueWithGoogle` component in TypeScript React handles Google login authentication, updates
 * local storage with tokens, sets axios headers, and navigates to the dashboard upon successful login.
 * @returns The `ContinueWithGoogle` component is returning a button that allows users to continue with
 * Google OAuth authentication. When the button is clicked, it triggers the `login` function which
 * initiates the Google login process. Upon successful login, the user's access token and other details
 * are stored in local storage, axios default headers are updated with the access token, and the user
 * is navigated to the dashboard.
 */
const ContinueWithGoogle: FC = () => {
  // AppRouter
  const navigate = useNavigate();

  // Toast message
  const { dispatchToast } = useToastController();

  // Local storage
  const { setItem } = useLocalStorage();

  // Error boundary
  const { showBoundary } = useErrorBoundary();

  // Google login response.
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        let { response }: any = await useConvertToken(tokenResponse.access_token);

        // Update details in localstorage
        setItem("access_token", response.data.access_token);
        setItem("refresh_token", response.data.refresh_token);
        setItem("expires_in", (Date.now() + 60 * 60 * 1000).toString());

        // Update axios default header
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;

        // navigate to dashboard
        navigate("dashboard");
      } catch (requestError) {
        if (requestError.code == "ERR_NETWORK") showBoundary(requestError);
      }
    },
    onError: async (errorResponse) => {
      console.error(errorResponse);
      dispatchToast(
        <Toast>
          <ToastTitle>Something went wrong, Please try log in with google on browser.</ToastTitle>
        </Toast>
      );
    },
  });

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY}>
      <Button size="medium" style={{ width: "100%" }} onClick={() => login()}>
        <Image src="assets/google.svg" alt="Google" width="25" height="25" shape="rounded" />
        <span style={{ paddingLeft: "10px" }}>Continue with Google</span>
      </Button>
    </GoogleOAuthProvider>
  );
};

export default ContinueWithGoogle;
