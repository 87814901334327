/**
 * The function `blobToBase64` converts a Blob object to a base64 encoded string asynchronously using
 * FileReader in TypeScript.
 * @param {Blob} blob - A Blob object represents a file-like object of immutable, raw data. It can be a
 * file, a stream, or other raw data.
 * @returns A Promise that resolves to a base64 string representation of the provided Blob.
 */
export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
