import * as React from "react";
import { AppRouter } from "./routes";
import { makeStyles, Toaster, tokens } from "@fluentui/react-components";
import errorLogging from "./services/errorLogging";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = React.lazy(() => import("./pages/ErrorFallback"));

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: tokens.colorNeutralBackground5Pressed,
  },
});

const title: string = "QR Mark Add-In";

/**
 * The `App` component in a TypeScript React application renders an `ErrorBoundary` component with
 * error handling and a `Toaster` component for displaying notifications.
 * @returns The `App` component is being returned. It contains a `div` element with a class name
 * derived from the `styles.root` variable. Inside the `div`, there is an `ErrorBoundary` component
 * with `FallbackComponent` set to `ErrorFallback` and `onError` set to `errorLogging`. Within the
 * `ErrorBoundary`, there is an `AppRouter` component with a
 */
const App = () => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.root}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorLogging}>
          <AppRouter title={title} />
          <Toaster />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default App;
