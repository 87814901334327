import { useLocalStorage } from "../../utils/hooks";

/**
 * The function `useIsAuthenticated` checks if the user is authenticated based on the presence of an
 * access token and its expiration time.
 * @returns A boolean value indicating whether the user is authenticated based on the presence of an
 * "access_token" in local storage and the expiration time compared to the current time.
 */
const useIsAuthenticated = () => {
  const { getItem } = useLocalStorage();
  return Boolean(getItem("access_token") && Date.now() < Number(getItem("expires_in")));
};

export default useIsAuthenticated;
