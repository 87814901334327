/**
 * The function `errorLogging` logs an error and additional information to the console.
 * @param {Error} error - The `error` parameter is of type `Error`, which represents an error object
 * that contains information about an error that occurred during the execution of the code. It
 * typically includes details such as the error message, stack trace, and other relevant information
 * related to the error.
 * @param info - The `info` parameter is an object with a property `componentStack` which is a string.
 * It seems like this parameter is used to provide additional information or context related to the
 * error being logged.
 */
const errorLogging = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  console.error(error);
  console.info(info);
};
export default errorLogging;
