import React from "react";
import BrandHeader from "../components/cards/BrandHeader";
import { makeStyles, shorthands } from "@fluentui/react-components";
import AboutVICard from "../components/cards/AboutVICard";
import FooterCard from "../components/cards/FooterCard";

const GenerateVIForm = React.lazy(() => import("../components/forms/GenerateVIForm"));

interface GenerateVerificationImageProps {}

const useStyles = makeStyles({
  main: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
});

/**
 * The GenerateVerificationImage component renders a layout with BrandHeader, AboutVICard,
 * GenerateVIForm, and FooterCard components.
 * @returns A React functional component named GenerateVerificationImage is being returned. It renders
 * a div element with className styles.main, which contains the following child components:
 * BrandHeader, AboutVICard, GenerateVIForm, and FooterCard.
 */
const GenerateVerificationImage: React.FC<GenerateVerificationImageProps> = () => {
  const styles = useStyles();
  return (
    <div className={styles.main}>
      <BrandHeader />
      <AboutVICard />
      <GenerateVIForm />
      <FooterCard />
    </div>
  );
};

export default GenerateVerificationImage;
