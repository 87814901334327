import React from "react";
import { Button, Menu, MenuItem, MenuItemLink, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { useLocalStorage } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";

const NavMenu = () => {
  // AppRouter
  const navigate = useNavigate();
  // Local Storage
  const { removeItem } = useLocalStorage();
  const signOut = () => {
    removeItem("email");
    removeItem("expires_in");
    removeItem("access_token");
    removeItem("refresh_token");
    removeItem("has_custom_domains");
    navigate("/");
  };
  return (
    <Menu>
      <MenuTrigger>
        <Button
          appearance="transparent"
          icon={<MoreHorizontal20Regular />}
          aria-label="More options"
          style={{ border: "1px solid #C8C8C8" }}
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuItemLink href="https://app.qrmark.com" target="_blank">
          Dashboard
        </MenuItemLink>
        <MenuItemLink href="https://qrmark.com/support" target="_blank">
          Support
        </MenuItemLink>
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </MenuPopover>
    </Menu>
  );
};
export default NavMenu;
