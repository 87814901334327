import { useAxios } from "../../utils/hooks";

/**
 * The function `useGenerateVI` is an asynchronous function that generates a verification image using
 * Axios with the provided document information and returns the response.
 * @param {string} documentName - The `documentName` parameter is a string that represents the name of
 * the document for which you want to generate a verification image.
 * @param {string} documentURL - The `documentURL` parameter typically refers to the URL where the
 * document is located or hosted. It is the web address that points to the specific document that you
 * want to generate a verification image for.
 * @param {string} domain - The `domain` parameter typically refers to the domain name of a website or
 * online platform where the verification image will be used. It helps identify the location or context
 * in which the verification image will be displayed or accessed.
 * @param {string} qrmarkDocumentID - The `qrmarkDocumentID` parameter is used to specify the office ID
 * for the QR code generation process. It is passed as a parameter to the API endpoint for generating
 * the verification image.
 * @returns The function `useGenerateVI` is returning an object with a property `response` that
 * contains the response from the Axios request made to the "qr/" endpoint. The response is expected to
 * be in blob format.
 */
const useGenerateVI = async (documentName: string, documentURL: string, domain: string, qrmarkDocumentID: string) => {
  // Generate verification image endpoint.
  const { response } = await useAxios({
    url: "qr/",
    method: "POST",
    data: {
      name: documentName,
      domain: domain,
      data: documentURL,
      source: "3",
      office_id: qrmarkDocumentID,
    },
    responseType: "blob",
  });

  return { response };
};
export default useGenerateVI;
