import React from "react";
import { Button, Card, Image, makeStyles } from "@fluentui/react-components";
import { useErrorBoundary } from "react-error-boundary";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
  body: {
    textAlign: "center",
  },
});

/* The `ErrorFallback` function component is defining a custom error boundary UI to be displayed when
an error occurs in the application. Here's a breakdown of what the component is doing: */
const ErrorFallback = () => {
  const styles = useStyles();
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className={styles.main}>
      <Card orientation="vertical">
        <Image src="assets/logo-filled.svg" alt="QR Mark" shape="rounded" fit="contain" style={{ width: "80%" }} />
      </Card>
      <Card>
        <Button appearance="outline" size="medium" onClick={resetBoundary}>
          Try Agan
        </Button>
      </Card>
      <Card className={styles.body}>
        <span>
          Something went wrong. If this issue persist, please reach out to us at
          <a style={{ padding: "5px 5px" }} href="mailto:support@qrmark.com" target="_blank" rel="noreferrer">
            support@qrmark.com
          </a>
        </span>
      </Card>
    </div>
  );
};
export default ErrorFallback;
