import axios, { AxiosRequestHeaders, Method, ResponseType } from "axios";

export interface PayloadProps {
  url: string;
  method?: Method;
  data?: object | null;
  params?: object | null;
  headers?: object | AxiosRequestHeaders;
  responseType?: ResponseType;
}

/**
 * Axios request configuration.
 * https://axios-http.com/docs/req_config
 */
// Request defaults config
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.method = "GET";
axios.defaults.maxRedirects = 0;
axios.defaults.responseType = "json" || "blob";

// Request headers config
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

// Request security headers config
// https://developer.okta.com/blog/2021/10/18/security-headers-best-practices
axios.defaults.headers.common["X-Frame-Options"] = "DENY";
axios.defaults.headers.common["X-XSS-Protection"] = 0;
// axios.defaults.headers.common['Content-Security-Policy'] = "default-src 'none'; " +
//     "script-src 'self' www.google-analytics.com 'unsafe-inline'; " +
//     "style-src 'self' https://fonts.googleapis.com 'unsafe-inline'; " +
//     "font-src 'self' https://fonts.gstatic.com; base-uri 'self';"
// axios.defaults.headers.common["Strict-Transport-Security"] = "max-age=31536000; includeSubDomains";

// Config global for axios/django
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export const useAxios = async (payload: PayloadProps) => {
  const response = await axios({
    url: payload.url,
    method: payload.method,
    data: payload.data,
    params: payload.params,
    headers: payload.headers,
    responseType: payload.responseType ?? "json",
  });
  return { response };
};
