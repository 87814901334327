import React, { useEffect, useState } from "react";
import { Dropdown, DropdownProps, Option, Toast, ToastTitle, useToastController } from "@fluentui/react-components";
import useVerifiedCDList from "../../services/customDomain/useVerifiedCDList";
import { useLocalStorage } from "../../utils/hooks";
import { useErrorBoundary } from "react-error-boundary";

/**
 * The `customDomainsDropdown` function fetches and displays a list of verified custom domains, with
 * error handling and toast notifications.
 * @param props - The `customDomainsDropdown` function takes a `Partial<DropdownProps>` object as its
 * `props` parameter. This object likely contains properties that can be passed to the `Dropdown`
 * component within the function. By using `Partial<DropdownProps>`, the function allows for passing
 * only a subset of
 * @returns The `customDomainsDropdown` function is returning a JSX element that consists of a
 * `Dropdown` component. The `Dropdown` component has various props passed to it, such as
 * `aria-labelledby`, `placeholder`, `size`, and any additional props passed through `props`. The
 * `Dropdown` component is disabled based on the condition `verifiedDomains === null`.
 */

const customDomainsDropdown = (props: Partial<DropdownProps>) => {
  // Toast dispatcher
  const { dispatchToast } = useToastController();

  // Error boundary
  const { showBoundary } = useErrorBoundary();

  // Local storage
  const localStorage = useLocalStorage();

  const [verifiedDomains, setVerifiedDomains] = useState<string[] | null>(null);

  useEffect(() => {
    // fetch custom domain verified names.
    const fetchCustomDomainVerifiedList = async () => {
      try {
        const { response } = await useVerifiedCDList();
        if (response.data.domains.length == 0) {
          localStorage.setItem("has_custom_domains", "0");
          dispatchToast(
            <Toast>
              <ToastTitle>Custom Domain does not setup. Please signup and add a custom domain.</ToastTitle>
            </Toast>
          );
        } else {
          localStorage.setItem("has_custom_domains", "1");
          setVerifiedDomains(response?.data.domains);
        }
      } catch (responseError) {
        if (responseError.code == "ERR_NETWORK") showBoundary(responseError);
        console.error(responseError);
        dispatchToast(
          <Toast>
            <ToastTitle>Something went wrong. Please reach out to us at support@qrmark.com</ToastTitle>
          </Toast>
        );
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchCustomDomainVerifiedList();
  }, []);

  return (
    <>
      <Dropdown
        aria-labelledby="custom-domain"
        placeholder="Select Custom Domain"
        size="medium"
        {...props}
        disabled={verifiedDomains === null}
      >
        {verifiedDomains && verifiedDomains.map((domain: string) => <Option key={domain}>{domain}</Option>)}
      </Dropdown>
    </>
  );
};

export default customDomainsDropdown;
