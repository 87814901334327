import React, { useState } from "react";
import {
  Button,
  Card,
  Field,
  Input,
  makeStyles,
  OptionOnSelectData,
  SelectionEvents,
  Toast,
  ToastTitle,
  tokens,
  useToastController,
} from "@fluentui/react-components";
import CustomDomainsDropdown from "../dropdowns/CustomDomainsDropdown";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { useLocalStorage } from "../../utils/hooks";
import useGenerateVI from "../../services/verification/useGenerateVI";
import useGenerateDocumentId from "../../services/verification/useGenerateDocumentId";
import { useErrorBoundary } from "react-error-boundary";
import { useInsertImage } from "../../utils/hooks/useInsertImage";

interface GenerateVIFormProps {}

interface GenerateVIFormInput {
  customDomain: string;
  documentName: string;
  documentURL: string;
}

const useStyles = makeStyles({
  card: {
    rowGap: "20px",
    display: "flex",
    flexDirection: "column",
  },
});

const GenerateVIForm: React.FC<GenerateVIFormProps> = () => {
  // Style
  const styles = useStyles();

  // Local storage
  const localStorage = useLocalStorage();

  // Error boundary
  const { showBoundary } = useErrorBoundary();

  // Toast
  const { dispatchToast } = useToastController();

  // State: Is using demo domain
  const [isDemoDomain, setIsDemoDomain] = useState<boolean>(false);

  // @ts-ignore
  const onCustomDomainSelection = (event: SelectionEvents, data: OptionOnSelectData) => {
    setValue("customDomain", data.optionValue);
    console.log(data.optionValue, "<<<OV");
    if (data.optionValue === process.env.REACT_APP_DEMO_CUSTOM_DOMAIN) setIsDemoDomain(true);
  };

  /**
   * The `customDomainErrorMessage` function is responsible for generating a custom error message based
   * on the validation errors related to the custom domain field in the form.
   * Here's a breakdown of what it does: */
  const customDomainErrorMessage = (errors: FieldErrors<GenerateVIFormInput>) => {
    // Custom error message in case of custom domain is not registered.
    const hasCustomDomains = Boolean(Number(localStorage.getItem("has_custom_domains"))) || false;
    if (errors?.customDomain?.type === "required" && !hasCustomDomains)
      return (
        <span>
          A custom domain is mandatory to add verification image to your document.
          <a
            href="https://app.qrmark.com/settings"
            style={{ textDecoration: "none", padding: "2px 2px" }}
            target="_blank"
            rel="noreferrer"
          >
            click here
          </a>
          to setup now.
          <a
            href="https://support.scanova.io/hc/en-us/articles/30124438394777-How-to-set-up-a-custom-domain-in-QR-Mark"
            target="_blank"
            style={{ textDecoration: "none", padding: "2px 2px" }}
            rel="noreferrer"
          >
            Learn more.
          </a>
        </span>
      );
    return errors?.customDomain?.message;
  };

  // Form submit
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<GenerateVIFormInput>({
    defaultValues: {
      customDomain: "",
      // eslint-disable-next-line
      documentName: Office.context.document.url.split("/").pop().slice(0, -5),
      documentURL: "",
    },
  });

  /**
   * Blob to json convertor.
   * @param blob
   */
  function blobToJson(blob: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          resolve(JSON.parse(reader.result as string));
        } catch (error) {
          reject(new Error("Failed to parse JSON: " + error.message));
        }
      };

      reader.onerror = () => {
        reject(new Error("Failed to read Blob: " + reader.error));
      };

      reader.readAsText(blob);
    });
  }
  /**
   * The function `generateVIFormSubmit` is an asynchronous function that generates a document image,
   * inserts it at the top of a document, and displays a toast message upon successful completion,
   * handling errors appropriately.
   * @param {GenerateVIFormInput} data - The `data` parameter in the `generateVIFormSubmit` function
   * contains the following properties:
   */
  const generateVIFormSubmit: SubmitHandler<GenerateVIFormInput> = async (data: GenerateVIFormInput) => {
    try {
      const qrmarkDocumentID: string = await useGenerateDocumentId();
      const { response } = await useGenerateVI(
        data.documentName,
        data.documentURL,
        data.customDomain,
        qrmarkDocumentID
      );

      const { insertInOfficeApp } = useInsertImage();
      insertInOfficeApp(response.data);
      reset({ documentName: data.documentName });
      dispatchToast(
        <Toast>
          <ToastTitle>Verification image generate and inserted at the top of the document. </ToastTitle>
        </Toast>
      );
    } catch (error) {
      if (error.code == "ERR_NETWORK") showBoundary(error);
      if (error.code === "ERR_BAD_REQUEST") {
        blobToJson(error.response.data).then((jsonError) => {
          dispatchToast(
            <Toast>
              <ToastTitle>{jsonError?.details.detail}</ToastTitle>
            </Toast>
          );
        });
      }
      console.log("Exception occur while generating document image ", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(generateVIFormSubmit)}>
        <Card orientation="vertical" className={styles.card}>
          <Field validationMessage={customDomainErrorMessage(errors)} label="Custom Domain">
            <CustomDomainsDropdown
              onOptionSelect={onCustomDomainSelection}
              {...register("customDomain", {
                required: "Please select a custom domain",
              })}
            />
            <div>
              {isDemoDomain && (
                <p style={{ color: "red" }}>
                  This domain should be used solely for demo purposes. Please refrain from using it beyond demos.
                  <a
                    href="https://support.qrmark.com/hc/en-us/articles/36475436493337-Setup-Custom-Domain"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      paddingLeft: "4px",
                      color: tokens.colorStatusSuccessForeground1,
                    }}
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              )}
            </div>
          </Field>
          <Field validationMessage={errors.documentName && errors.documentName.message} label="Document Name">
            <Input
              name="name"
              type="text"
              placeholder="Enter document name"
              {...register("documentName", {
                required: "Document name is required",
                minLength: 3,
                maxLength: 200,
                value: getValues("documentName"),
              })}
            />
          </Field>
          <Field validationMessage={errors.documentURL && errors.documentURL.message} label="Document Link">
            <Input
              name="link"
              type="text"
              placeholder="Enter document link"
              {...register("documentURL", {
                required: "Please update the share URL",
                min: 5,
                max: 200,
                pattern: {
                  value:
                    /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+[\-]+[my.sharepoint.com]+[\/:]+[a-z]{1}[:\/]+[a-z]{1}[\/]+[a-z]+[\/]?(.*)$/,
                  message: "Invalid Share URL",
                },
              })}
            />
          </Field>
          <p style={{ color: "gray", fontSize: "small", fontStyle: "italic" }}>
            You can obtain the document link by clicking on 'Share' button and selecting 'Copy Link'.
          </p>
          <Button type="submit" size="medium" style={{ backgroundColor: "#448E3C ", color: "white" }}>
            Generate Verification Image
          </Button>
        </Card>
      </form>
    </>
  );
};

export default GenerateVIForm;
