import { Card, tokens } from "@fluentui/react-components";
import React from "react";

/**
 * The AboutVICard component renders a Card with a vertical orientation containing a text element about
 * generating a QR Code as a verification image.
 * @returns A functional component named AboutVICard is being returned. It renders a Card component
 * with a vertical orientation containing a span element with text "Generate QR Code as a verification
 * image". The span element has inline styles for font weight, font size, text alignment, and color.
 */

const AboutVICard = () => {
  return (
    <>
      <Card orientation="vertical">
        <span
          style={{
            fontWeight: tokens.fontWeightSemibold,
            fontSize: tokens.fontSizeBase200,
            textAlign: "center",
            color: "#858585",
          }}
        >
          Generate QR Code as a verification image
        </span>
      </Card>
    </>
  );
};
export default AboutVICard;
