import * as React from "react";
import { Card, Image, makeStyles, tokens } from "@fluentui/react-components";
import ContinueWithGoogle from "../components/buttons/ContinueWithGoogle";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useIsAuthenticated from "../services/authentication/useIsAuthenticated";
import { Navigate } from "react-router-dom";

const SignInForm = React.lazy(() => import("../components/forms/SignInForm"));
export interface SignInProps {
  title: string;
  logo: string;
  message: string;
}

const useStyles = makeStyles({
  header: {
    alignItems: "center",
    paddingTop: "40px",
    paddingBottom: "30px",
  },
  header__h1: {
    fontFamily: "Jost",
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightMedium,
    color: tokens.colorNeutralForeground4,
  },
  signin_card: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "80px",
  },
  signin__text: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px",
    color: tokens.colorNeutralForeground4,
    fontWeight: tokens.fontWeightMedium,
  },
  signin__p: {
    textAlign: "center",
  },
  signin_google: {
    marginTop: "5px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  common: {
    marginTop: "20px",
    alignItems: "center",
    fontSize: "medium",
  },
  common_p: {
    textAlign: "center",
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground4,
    fontWeight: tokens.fontWeightMedium,
  },
  footer: {
    alignItems: "center",
    marginTop: "15px",
  },
});

/* This code defines a functional component named `SignIn` which takes in props of type `SignInProps`.
Inside the component, it destructures the `title`, `logo`, and `message` from the props. */
const SignIn: React.FC<SignInProps> = (props: SignInProps) => {
  const { title, logo, message } = props;
  const styles = useStyles();

  // Auth
  const isAuthenticated: boolean = useIsAuthenticated();

  if (isAuthenticated) return <Navigate to="dashboard" replace={true} />;

  return (
    <>
      <Card orientation="vertical" className={styles.header}>
        <Image src={logo} alt={title} shape="rounded" fit="contain" style={{ width: "80%" }} />
        <h1 className={styles.header__h1}>{message}</h1>
      </Card>

      <Card orientation="vertical" className={styles.signin_card}>
        <section style={{ textAlign: "center" }}>
          <p className={styles.signin__text}>Sign in to your account</p>
          <SignInForm />
        </section>
        <p className={styles.signin__p}></p>
        <div style={{ borderBottom: "1px solid #C8C4C3", textAlign: "center", lineHeight: "0.1em", margin: "10px" }}>
          <span style={{ backgroundColor: "white", padding: "0 10px" }}>or</span>
        </div>

        <section className={styles.signin_google}>
          <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_KEY}`}>
            <ContinueWithGoogle />
          </GoogleOAuthProvider>
        </section>
      </Card>

      <Card className={styles.common}>
        <p className={styles.common_p}>
          <a
            href="https://app.qrmark.com/signup"
            target="_blank"
            style={{ textDecoration: "none", paddingRight: "4px", fontSize: "small" }}
            rel="noreferrer"
          >
            Click here
          </a>
          to sign up. Visit{" "}
          <a
            href="https://qrmark.com"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", paddingRight: "4px", fontSize: "small" }}
          >
            https://qrmark.com
          </a>{" "}
          to know more. QR Mark is currently in beta and free to use. T&C apply.
          <a href="https://qrmark.com/how-to-use" target="_blank" rel="noreferrer">
            Learn more.
          </a>
        </p>
      </Card>
    </>
  );
};

export default SignIn;
