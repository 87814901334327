import { useAxios } from "../../utils/hooks";

/**
 * The function `useConvertToken` asynchronously converts a Google access token to a different type of
 * token using Axios.
 * @param {string} access_token - An access token is a credential that is used by an application to
 * access an API. It is typically obtained after a user authenticates and authorizes the application to
 * access their data on a specific service, such as Google, Facebook, or another platform. The access
 * token serves as a proof of the user
 * @returns The function `useConvertToken` is returning an object with a property `response` that
 * contains the response data from the API call made using Axios.
 */
const useConvertToken = async (access_token: string) => {
  const { response } = await useAxios({
    url: "auth/token/google/",
    method: "POST",
    data: {
      grant_type: "convert_token",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      backend: "google-oauth2",
      token: access_token,
    },
  });
  return { response };
};

export default useConvertToken;
