/**
 * The function `generateUUID` generates a random UUID (Universally Unique Identifier) string.
 * @returns A UUID (Universally Unique Identifier) is being returned.
 */
export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r: number = (Math.random() * 16) | 0;
    const v: number = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
