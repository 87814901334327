import { AddInsConstants } from "../../Constants";
import { generateUUID } from "../../utils/helpers";
import { useOfficeProperties } from "../../utils/hooks";

/**
 * The function `useGenerateDocumentId` generates a unique document ID and stores it in custom
 * properties if not already present.
 * @returns The function `useGenerateDocumentId` returns the unique document ID (qrmarkDocumentID)
 * after generating and setting it in the custom properties. If the document ID already exists in the
 * custom properties, it retrieves the existing ID.
 */
const useGenerateDocumentId = async () => {
  // Generate Unique document ID.
  const { getCustomProperties, setCustomProperties } = useOfficeProperties();
  // Check the document id is in the custom properties.
  // Retrieving unique document id: https://stackoverflow.com/questions/48194643/officejs-retrieving-document-id
  const qrmarkDocumentID = await getCustomProperties(AddInsConstants.CustomPropertyKey);
  // If custom id is in not in the custom properties then generate and set in the custom property.
  console.log("Do we have qrmark document id ", qrmarkDocumentID);

  if (!qrmarkDocumentID) {
    const qrmarkDocumentID: string = generateUUID();
    // setImmediate(() => setCustomProperties(AddInsConstants.CustomPropertyKey, qrmarkDocumentID));
    await setCustomProperties(AddInsConstants.CustomPropertyKey, qrmarkDocumentID);
    return qrmarkDocumentID;
  } else {
    return qrmarkDocumentID;
  }
};
export default useGenerateDocumentId;
