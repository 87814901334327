import * as React from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Mail24Regular, Password24Regular } from "@fluentui/react-icons";
import useGenerateToken from "../../services/authentication/useGenerateToken";
import { Button, Field, Input, makeStyles } from "@fluentui/react-components";
import { useLocalStorage } from "../../utils/hooks";
import axios from "axios";
import { useErrorBoundary } from "react-error-boundary";

interface SignInFormInput {
  email: string;
  password: string;
}

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
    rowGap: "25px",
  },
});

const SigninForm: FC = () => {
  // Style
  const styles = useStyles();

  // AppRouter
  const navigate = useNavigate();

  // Localstorage
  const { setItem } = useLocalStorage();

  // Error boundary
  const { showBoundary } = useErrorBoundary();

  // Forms
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isValid },
  } = useForm<SignInFormInput>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  /**
   * The function signInFormSubmit handles form submission for signing in, generating tokens, updating
   * local storage, setting headers, and navigating to the dashboard.
   * @param data - The `data` parameter in the `signInFormSubmit` function likely contains the input
   * values from the sign-in form, such as the user's email and password. This data is used to attempt a
   * sign-in by calling the `useGenerateToken` function with the email and password provided.
   */
  const signInFormSubmit: SubmitHandler<SignInFormInput> = async (data) => {
    try {
      let { response }: any = await useGenerateToken(data.email, data.password);

      // Update details in localstorage
      setItem("access_token", response.data.access_token);
      setItem("refresh_token", response.data.refresh_token);
      setItem("email", data.email);
      setItem("expires_in", (Date.now() + 60 * 60 * 1000).toString());

      // Update axios default header
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;

      // navigate to dashboard
      navigate("dashboard");
    } catch (errorResponse) {
      if (errorResponse.code == "ERR_NETWORK") showBoundary(errorResponse);
      setError("email", { message: "Invalid credentials, Please ensure that your email is correct" });
      setError("password", { message: "Invalid credentials, Please ensure that your password is correct" });
    }
  };

  return (
    <form onSubmit={handleSubmit(signInFormSubmit)} className={styles.form}>
      <Field validationMessage={errors.email && errors.email.message} validationState={errors.email ? "error" : "none"}>
        <Input
          name="email"
          contentBefore={<Mail24Regular />}
          placeholder="Email"
          required
          {...register("email", {
            required: "Email is required",
            maxLength: { value: 250, message: "Email should be less then 250 characters" },
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Invalid email format" },
          })}
        />
      </Field>
      <Field
        validationMessage={errors.password && errors.password.message}
        validationState={errors.password ? "error" : "none"}
      >
        <Input
          name="password"
          contentBefore={<Password24Regular />}
          placeholder="Password"
          type="password"
          {...register("password", {
            required: "Password is required",
            minLength: { value: 8, message: "Password should be of minimum 8 characters" },
          })}
        />
      </Field>
      <Button appearance="outline" size="medium" type="submit" disabled={!isValid}>
        Sign In
      </Button>
    </form>
  );
};

export default SigninForm;
