import { useAxios } from "../../utils/hooks";

// interface AuthTokenResponse {
//   access_token: string;
//   expires_in?: number;
//   refresh_token: string;
//   token_type: string;
//   scope?: string;
// }

/**
 * The function `useGenerateToken` is used to generate a token by sending a POST request with user
 * credentials.
 * @param {string} email - The `email` parameter is a string that represents the email address of the
 * user trying to authenticate and generate a token.
 * @param {string} password - The `password` parameter in the `useGenerateToken` function is a string
 * that represents the user's password. It is used along with the `email` parameter to authenticate the
 * user and generate a token for authorization purposes.
 * @returns The function `useGenerateToken` is returning an object with a property `response` that
 * contains the response from the API call made to "auth/token/" using Axios.
 */
const useGenerateToken = async (email: string, password: string) => {
  // Form data
  const form: FormData = new FormData();
  form.append("grant_type", "password");
  form.append("client_id", process.env.REACT_APP_CLIENT_ID);
  form.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  form.append("email", email);
  form.append("password", password);

  const { response } = await useAxios({
    url: "auth/token/",
    method: "POST",
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return { response };
};

export default useGenerateToken;
